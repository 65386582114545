<template>
    <div class="internal-sections">
        <div class="internal-sections-table">
            <table class="table-my-code2 table-bordered" v-loading="loadingData">
                <thead>
                    <tr>
                        <th  class="numbert-table"  v-if="columns.id.show">
                            <span>{{columns.id.title}}</span>
                        </th>
                        <th  v-if="columns.status.show">
                            {{ columns.status.title }}
                        </th>

                        <th v-if="columns.license.show">
                            {{ columns.license.title }}
                        </th>

                        <th v-if="columns.prosent.show">
                            {{ columns.prosent.title }}
                        </th>

                        <th v-if="columns.statement_type.show">
                            {{ columns.statement_type.title }}
                        </th>

                        <th v-if="columns.organization.show">
                            {{ columns.organization.title }}
                        </th>

                       
                        <th v-if="columns.application_number.show">
                            {{ columns.application_number.title }}
                        </th>

                        <th v-if="columns.cabinetType.show">
                            {{ columns.cabinetType.title }}
                        </th>

                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                        <th class="off-table" v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>
                </thead>

                  <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="(item, index) in list" :key="index"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">
                            {{item.id}}
                        </td>

                        <td v-if="columns.status.show">
                            <div  class="success-bg">
                                <span v-if="item.condition_id == 5">Muvaffaqiyatli</span>
                            </div>  
                        </td>

                        <td v-if="columns.license.show">
                            {{ item.license_procedure ? item.license_procedure.name : ''}}
                        </td>

                        <td v-if="columns.prosent.show">
                            {{item.prosent ? item.prosent : 0}}
                        </td>

                        <td v-if="columns.statement_type.show">
                            <div  class="success-bg">
                                <span v-if="item.parent.application_type">Yangi</span>
                                <span v-else>Qayta ariza</span>
                            </div><br>
                        </td>

                        <td v-if="columns.organization.show">
                            <div  class="primary-bg">
                                {{ item.parent ? item.parent.clinic.inn : ''}}
                            </div><br>
                            {{ item.parent ? item.parent.clinic.legal_entity_name : '' }}
                        </td>

                     
                        <td v-if="columns.application_number.show">
                            <div  class="primary-bg">
                                {{ item.application_number }}
                            </div>  
                        </td>

                        <td v-if="columns.cabinetType.show">
                            <div  class="success-bg">
                                <span v-if="item.parent.cabinet_type">Back office</span>
                                <span v-else>Front office</span>
                            </div><br>
                        </td>

                        <td v-if="columns.created_at.show">
                            {{ item.created_at }}
                        </td>

                        <td class="off-btn" v-if="columns.settings.show">
                            <router-link :to="{ name: 'confirmList', params: {id: item.id, application_result_id: item.application_result_id} }">
                                Батафсил <i class="el-icon-right"></i>
                            </router-link>
                        </td>
                    </tr>
                </transition-group>
            </table>
             <div class="my-pagination" v-if="list.length > 0">
                <crm-pagination
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
            <div class="none-data" v-show="shown">
                <i class="el-icon-box"></i>
                <div>No Data</div>
            </div>
        </div>
    </div>
    
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import _ from 'lodash';
export default {
    name: "newApplications",
    props: ['prop_item', 'refresh_item'],
    components: {
    },
     computed: {
        ...mapGetters({
            list: "submitApplications/list",
            confirmation: "submitApplications/confirmation",
            columns: "submitApplications/columns",
            pagination: "submitApplications/pagination",
        }),
        actions: function () {
            return ["received"];
        },
    },
    watch: {
        prop_item(newVal){
            if (newVal) {
                let debounce_func = _.debounce(this.fetchData, 500);
                debounce_func();
                this.$emit('after-props');
            }
        },
        refresh_item(r_status){
            if (r_status) {
                let debounce_func = _.debounce(this.fetchData, 500);
                debounce_func();
                this.$emit('after-props');
            }
        },
        'pagination.page': {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.fetchData)) {
                    let debounce_func = _.debounce(this.fetchData, 500);
                    debounce_func();
                }
            },
            deep: true,
            // immediate: true,
        },
        'pagination.per_page': {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.fetchData)) {
                    let debounce_func = _.debounce(this.fetchData, 500);
                    debounce_func();
                }
            },
            deep: true,
            // immediate: true,
        },
    },
    created() {
        let debounce_func = _.debounce(this.fetchData, 500);
        debounce_func();
    },
    data() {
        return { 
            loadingData: false,
            shown: false
        };
    },
     methods: {
        ...mapActions({
            updateList: "submitApplications/index",
            updateSort: "submitApplications/updateSort",
            updateFilter: "submitApplications/updateFilter",
            updateColumn: "submitApplications/updateColumn",
            updatePagination: "submitApplications/updatePagination",
            show: "submitApplications/show",
            empty: "submitApplications/empty",
            delete: "submitApplications/destroy",
            refreshData: "submitApplications/refreshData",
        }),
        fetchData() { 
            const query = {...this.pagination, condition_id: 5, confirm: true};
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false;
                    if (!_.isEmpty(this.confirmation)) { this.$emit('counts', this.confirmation); }
                    if (_.isEmpty(res.application_results)) {
                        setTimeout(() => { this.shown = true; }, 200);
                    }
                }).catch(err => {
                    this.loadingData = false;
                });
            }
        },
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('submitApplications/EMPTY_LIST');
        next();
    },
};
</script>
